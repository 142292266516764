import classNames from "classnames";
import { FC, InputHTMLAttributes } from "react";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

const TextField: FC<IProps> = ({ error, ...rest }) => {
  return (
    <div>
      <input
        {...rest}
        className={classNames("w-full border-b border-b-[rgb(102,102,102)] py-[6px] pr-[10px] outline-none focus:border-b-[rgb(0,103,184)]", {
          "border-b-red-500": error,
        })}
      />
    </div>
  );
};

export default TextField;
