import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext } from "react";

export type sections = "signin" | "signin_option" | "password";

interface IProps extends PropsWithChildren {
  values: ISigninPageContext;
}

interface ISigninPageContext {
  section: sections;
  setSection: Dispatch<SetStateAction<sections>>;
  ref_signin: any;
  ref_signin_option: any;
  ref_password: any;
}

const SigninContext = createContext<ISigninPageContext>({
  section: "signin",
  setSection: () => {},
  ref_signin: undefined,
  ref_signin_option: undefined,
  ref_password: undefined,
});

export const useSigninPageContext = () => useContext(SigninContext);

const SigninProvider: FC<IProps> = ({ values: { section, setSection, ref_signin, ref_signin_option, ref_password }, children }) => {
  return <SigninContext.Provider value={{ section, setSection, ref_signin, ref_signin_option, ref_password }}>{children}</SigninContext.Provider>;
};
export default SigninProvider;
