import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import useWebSocket from "../hooks/useWebSocket";
import API from "./../services/config.json";
import { useNavigate } from "react-router";
import { findValueParamd } from "../utils/functions";

interface IWebSocketContext {
  sendMessage: (data: any, cb?: () => void) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  listeningMessage: (cb: (e: MessageEvent<any>) => void) => void;
  data: any;
  setData: Dispatch<SetStateAction<any>>;
  error: string | undefined;
  setError: Dispatch<SetStateAction<any>>;
  page: string;
  setPage: Dispatch<SetStateAction<string>>;
}

let vd = () => {};
const WebSocketContext = createContext<IWebSocketContext>({
  sendMessage: vd,
  listeningMessage: vd,
  loading: false,
  setLoading: vd,
  data: [],
  setData: vd,
  error: undefined,
  setError: vd,
  page: "not_found",
  setPage: vd,
});

export const useWebSocketContext = () => useContext(WebSocketContext);

const WebSocketProvider = ({ page, setPage, children }: { page: string; setPage: Dispatch<SetStateAction<string>>; children: ReactNode }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [sendMessage, listeningMessage] = useWebSocket(API.WEB_SOCKET_URL);
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  listeningMessage((e) => {
    let d = JSON.parse(e.data);
    let isOrg = d.params.find((i: any) => i.orgSite);
    if (Boolean(isOrg)) {
      window.location.replace(isOrg.addr);
    }
    if (d.params) {
      setData(d.params);
      const error_data = d.params.find((i: any) => i.error);
      if (error_data) {
        setError(error_data.error);
      }else{
        setError(undefined);
      }
    }
    // localStorage.setItem("_e", email);
    setPage(d.page);
    navigate(`/${localStorage.getItem("_l")}`, { state: { page: d.page } });
    setLoading(false);
  });
  return (
    <WebSocketContext.Provider value={{ sendMessage, listeningMessage, loading, setLoading, data, setData, error, setError, page, setPage }}>{children}</WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
