const NotFound = () => {
  return (
    <div className="w-full h-full">
      <div className="mb-6 flex justify-center">
        <img src="/outlook.png" alt="outlook" className="max-h-9" />
      </div>
      <div className="mt-64">
        <div className="text-center text-5xl">404</div>
        <div className="text-center text-4xl">Not Found</div>
      </div>
    </div>
  );
};

export default NotFound;
