const DotLoading = () => (
  <div className="progress-bar relative block w-full h-1 overflow-hidden">
    <div className="dot absolute w-full block after:block after:w-1 after:h-1 after:rounded-full after:bg-bg-primary"></div>
    <div className="dot absolute w-full block after:block after:w-1 after:h-1 after:rounded-full after:bg-bg-primary"></div>
    <div className="dot absolute w-full block after:block after:w-1 after:h-1 after:rounded-full after:bg-bg-primary"></div>
    <div className="dot absolute w-full block after:block after:w-1 after:h-1 after:rounded-full after:bg-bg-primary"></div>
    <div className="dot absolute w-full block after:block after:w-1 after:h-1 after:rounded-full after:bg-bg-primary"></div>
  </div>
);

export default DotLoading;
