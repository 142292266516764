import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
interface IProps extends PropsWithChildren {
  classname?: string;
}

const Card: FC<IProps> = ({ classname, children }) => {
  return (
    <div className={twMerge(classNames("bg-white overflow-hidden max-md:px-6 px-11 py-2 w-full md:min-w-[440px] border border-black md:border-0", classname))} style={{ boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 6px" }}>
      {children}
    </div>
  );
};

export default Card;
