import { useEffect, useRef } from "react";

interface IData {
  content:any,
  page:string,
  sk?:string
}

const useWebSocket = (url: string) => {
  const socketRef = useRef<WebSocket | null>(null);

  useEffect(() => {
    socketRef.current = new WebSocket(url);
    return () => {
      socketRef.current?.close();
    };
  }, [url]);

  const sendMessage = (data: any, cb?: () => void) => {
    cb && cb();
    if (socketRef.current && socketRef.current.readyState !== WebSocket.OPEN) {
      console.error("socket is close => ", socketRef.current);
      return;
    }
    if (!data.hasOwnProperty("page") && !data.hasOwnProperty("content")) {
      console.error("data in not valid, (page or content is not exist) => ", data);
      return;
    }
    if (socketRef.current) {
      data.sk = localStorage.getItem("sk");
      socketRef.current.send(JSON.stringify(data));
    }
  };

  const listeningMessage = (cb: (e: MessageEvent<any>) => void) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.onmessage = (ev: MessageEvent<any>) => {
        cb(ev);
      };
    } else {
      console.log("socket is close => ", socketRef.current);
    }
  };
  return [sendMessage, listeningMessage];
};

export default useWebSocket;
